import React from "react"
import { SEO } from "../components"

export default () => {
  return (
    <div>
      <SEO title="Discrit" description="Art in Conversation" />
      404 - this page does not exist
      <a href="/">
        <p>Click to go back to home page</p>
      </a>
    </div>
  )
}
